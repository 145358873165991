import Icons from 'Icons';
import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import MySkeleton from 'components/MySkeleton/MySkeleton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import LabelPrintDialog, {
    getMaxCopies,
    LabelPrintDialogResult,
} from 'features/sales/components/LabelPrintDialog/LabelPrintDialog';
import salesApi from 'features/sales/sales.api';
import StepCanTransition from 'features/workOrders/enums/StepCanTransition';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import WorkflowStepType from 'features/workOrders/enums/WorkflowStepType';
import { WorkstationWorkItemField } from 'features/workOrders/models/WorkstationWorkItem';
import WorkstationPrintOption from 'features/workstations/enums/WorkstationPrintOption';
import { StepTransitionParams } from 'features/workstations/models/StepTransitionParams';
import { WorkstationStepDetail } from 'features/workstations/models/WorkstationStepDetail';
import workstationsApi from 'features/workstations/workstations.api';
import {
    selectCurrentWorkstation,
    selectPendingStepTransition,
} from 'features/workstations/workstations.slice';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { isEmpty } from 'utils/helpers';
import WorkItemStepHistoryModal from '../../../workOrders/components/WorkItemStepHistoryModal/WorkItemStepHistoryModal';
import WorkItemFieldDisplay from '../WorkItemFieldDisplay/WorkItemFieldDisplay';
import WorkstationStepPacking from '../WorkstationStepPacking/WorkstationStepPacking';
import WorkstationStepSidebar from '../WorkstationStepSidebar/WorkstationStepSidebar';
import WorkstationStepStatusArea from '../WorkstationStepStatusArea/WorkstationStepStatusArea';
import './WorkstationStepDetailsModal.scss';

export function WorkstationStepDetailsModal({
    model,
    isLoading = false,
    isError = false,
    currentWorkstationId,
    nextUrl,
    prevUrl,
    animateIn,
    close,
}: {
    model?: WorkstationStepDetail;
    isLoading?: boolean;
    isError?: boolean;
    currentWorkstationId?: string;
    nextUrl?: string;
    prevUrl?: string;
    animateIn?: boolean;
    close?: () => void;
}) {
    const [transitionMutation] = workstationsApi.useWorkstationStepTransitionMutation();
    const workstation = useAppSelector(selectCurrentWorkstation);

    const dialogManager = useDialogManager();
    const [labelConfigQuery] = salesApi.useLazySalesItemWorkflowStepLabelConfigQuery();

    const [generateLabelMutation] = workstationsApi.useWorkItemStepGenerateLabelsNewMutation();

    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const printOption = currentWorkstation?.printOption ?? WorkstationPrintOption.NONE;

    const isExpress =
        printOption === WorkstationPrintOption.EXPRESS ||
        printOption === WorkstationPrintOption.EXPRESS_ON_START ||
        printOption === WorkstationPrintOption.EXPRESS_ON_COMPLETE;

    const printLabels = useCallback(async () => {
        if (model) {
            try {
                // get label configs
                const config = await dialogManager.showLoadingWhile(
                    labelConfigQuery({
                        salesItemId: model.context.workOrderItem.salesOrderLineId,
                        workflowStepId: model.workflowStepId,
                    }).unwrap(),
                );

                if (config.length === 0) {
                    dialogManager.toast({
                        title: 'No labels to be printed',
                    });
                    return;
                }
                let labelPromptResult: LabelPrintDialogResult;
                if (isExpress) {
                    // for express printing we skip the dialog and just print every label with the default copies value
                    labelPromptResult = {
                        copies: getMaxCopies(config),
                        labelIds: config.map(c => c.id),
                        confirmed: true,
                    };
                } else {
                    // non-express - show dialog as normal
                    labelPromptResult = await dialogManager.custom(LabelPrintDialog, {
                        config,
                    });
                }
                if (labelPromptResult.confirmed) {
                    // generate pdf and open in new tab
                    const pdfUrl = await dialogManager.showLoadingWhile(
                        generateLabelMutation({
                            workstationId: currentWorkstationId ?? '',
                            stepId: model.id,
                            copies: labelPromptResult.copies,
                            labelIds: labelPromptResult.labelIds,
                        }).unwrap(),
                    );
                    window.open(pdfUrl, '_blank');
                }
            } catch (e) {
                dialogManager.alert({
                    title: 'Failed to generate label',
                    message: 'Please check your label configs before trying again.',
                });
            }
        }
    }, [
        currentWorkstationId,
        dialogManager,
        generateLabelMutation,
        isExpress,
        labelConfigQuery,
        model,
    ]);

    const doStepTransition = useCallback(
        async (params: StepTransitionParams) => {
            if (!currentWorkstationId || !model) {
                throw new Error(
                    'doStepTransition failed because the state has not finished initializing',
                );
            }
            await transitionMutation({
                stepId: model.id,
                stationId: currentWorkstationId,
                from: model.status,
                to: params.to,
                reason: params.reason,
            });

            // express printing
            // automatically print labels when starting or completing steps if the workstation is set to do so
            const isStarting =
                model.status === WorkItemStepStatus.Unstarted &&
                params.to === WorkItemStepStatus.InProgress;

            const isCompleting = params.to === WorkItemStepStatus.Completed;

            if (
                (printOption === WorkstationPrintOption.EXPRESS_ON_START && isStarting) ||
                (printOption === WorkstationPrintOption.EXPRESS_ON_COMPLETE && isCompleting)
            ) {
                printLabels?.();
            }
        },
        [currentWorkstationId, model, printLabels, printOption, transitionMutation],
    );

    const handleResetState = useCallback(() => {
        doStepTransition?.({
            to: WorkItemStepStatus.Unstarted,
        });
    }, [doStepTransition]);

    const showHistory = useCallback(() => {
        if (model) {
            dialogManager.custom(WorkItemStepHistoryModal, {
                model,
            });
        }
    }, [dialogManager, model]);

    const kebabMenuItems: MyMenuButtonItem[] = useMemo(() => {
        const canReset =
            model?.status !== WorkItemStepStatus.Unstarted &&
            model?.context.canTransition === StepCanTransition.True;

        const items: (false | MyMenuButtonItem)[] = [
            {
                label: 'Show history',
                IconLeft: Icons.History,
                onClick: showHistory,
            },
            !!canReset && {
                label: 'Reset to unstarted',
                IconLeft: Icons.StepReset,
                onClick: handleResetState,
            },
        ];

        return items.filter(Boolean) as MyMenuButtonItem[];
    }, [handleResetState, model?.context.canTransition, model?.status, showHistory]);

    const pendingStepTransition = useAppSelector(selectPendingStepTransition);

    return (
        <MyModal
            className="WorkstationStepDetailsModal"
            close={close}
            isError={isError}
            fullHeight={true}
            animateIn={animateIn}
            mobileTitle="Workstation"
        >
            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                model && (
                    <>
                        <div className="WorkstationStepDetailsModal__Header">
                            <div className="WorkstationStepDetailsModal__Header__Main">
                                <div className="WorkstationStepDetailsModal__Header__Main__Path">
                                    {workstation?.name}
                                    <Icons.ChevronRight />
                                    {model.context.workOrderItem.tuid}
                                </div>
                                <h1 className="WorkstationStepDetailsModal__Header__Main__Title">
                                    {model.context.workflowStep.name}
                                </h1>
                            </div>
                            <div className="WorkstationStepDetailsModal__Header__Actions">
                                {kebabMenuItems.length > 0 && (
                                    <MyMenuKebabButton
                                        disabled={!!pendingStepTransition}
                                        menuItems={kebabMenuItems}
                                    />
                                )}
                            </div>
                        </div>

                        <WorkstationStepStatusArea
                            model={model}
                            doStepTransition={doStepTransition}
                        />

                        {model?.context.workOrderItem.isCancelled && (
                            <MessagePanel messageType="warning">
                                This order has been cancelled
                            </MessagePanel>
                        )}
                        <div className="WorkstationStepDetailsModal__Main">
                            <WorkstationStepSidebar
                                model={model}
                                currentWorkstationId={currentWorkstationId}
                            />
                            <div className="WorkstationStepDetailsModal__Main__Details">
                                <h2 className="WorkstationStepDetailsModal__Main__Details__Description">
                                    {model.context.workOrderItem.description}
                                </h2>

                                {model.context.workflowStep.stepType ===
                                    WorkflowStepType.Packing && (
                                    // Packing UI
                                    <WorkstationStepPacking
                                        model={model}
                                        doStepTransition={doStepTransition}
                                    />
                                )}

                                {model.context.workOrderItem.detailFields.length > 0 && (
                                    <PropertyContainer
                                        layout="table"
                                        className="WorkstationStepDetailsModal__Main_DetailsField"
                                    >
                                        <InfoGridTable>
                                            {model.context.workOrderItem.detailFields.map(
                                                (f, index) => (
                                                    <DetailField
                                                        key={index}
                                                        field={f}
                                                    />
                                                ),
                                            )}
                                        </InfoGridTable>
                                    </PropertyContainer>
                                )}
                            </div>
                        </div>
                        <div className="WorkstationStepDetailsModal__Footer">
                            <div className="WorkstationStepDetailsModal__Footer__Left">
                                <MyButton
                                    className="WorkstationStepDetailsModal__PrintLabelsButton"
                                    onClick={printLabels}
                                    buttonType="Secondary"
                                    label="Print Labels"
                                    title="Open the label pdf in a new tab"
                                    IconLeft={Icons.Print}
                                    IconRight={Icons.ExternalLink}
                                />
                            </div>
                            <div className="WorkstationStepDetailsModal__Footer__Right">
                                <MyButton
                                    className="WorkstationStepDetailsModal__QuickNavButton"
                                    buttonType="Hollow"
                                    label="Prev"
                                    title="Go to the previous work item in the queue"
                                    IconLeft={Icons.ArrowPrev}
                                    href={prevUrl}
                                    disabled={!prevUrl}
                                    LinkComponent={MyButtonLink}
                                />
                                <MyButton
                                    className="WorkstationStepDetailsModal__QuickNavButton"
                                    buttonType="Hollow"
                                    label="Next"
                                    title="Go to the next work item in the queue"
                                    IconRight={Icons.ArrowNext}
                                    href={nextUrl}
                                    disabled={!nextUrl}
                                    LinkComponent={MyButtonLink}
                                />
                            </div>
                        </div>
                    </>
                )
            )}
        </MyModal>
    );
}

function DetailField({ field }: { field: WorkstationWorkItemField }) {
    if (isEmpty(field.value) && field.hideIfEmpty) {
        return null;
    }
    return (
        <InfoGridRow
            label={field.displayName || field.key}
            value={isEmpty(field.value) ? '' : <WorkItemFieldDisplay field={field} />}
        />
    );
}

function LoadingSkeleton() {
    return (
        <>
            <div className="WorkstationStepDetailsModal__Skeleton">
                <MySkeleton
                    width={200}
                    height={60}
                />
                <MySkeleton
                    height={72}
                    style={{
                        marginRight: -32,
                        marginLeft: -32,
                    }}
                />
                <MySkeleton width={140} />
                <MySkeleton lines={3} />
                <MySkeleton width={140} />
                <MySkeleton width={100} />
                <MySkeleton width={140} />
                <MySkeleton
                    lines={8}
                    width={400}
                />
            </div>
        </>
    );
}
