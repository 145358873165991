import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import customersApi from 'features/customers/customers.api';
import CustomerAccessType from 'features/customers/enums/CustomerAccessType';
import { CustomerHoldStatusDisplay } from 'features/customers/enums/CustomerHoldStatus';
import { CustomerPaymentMethodDisplay } from 'features/customers/enums/CustomerPaymentMethod';
import { Customer } from 'features/customers/models/Customer';
import React, { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import './CustomersTable.scss';

const COLUMN_DEFS = ColumnBuilder<Customer>()
    .column({
        label: 'Name',
        key: 'name',
        isSortable: true,
        getValue: item => item.context.mainCompany.name,
    })
    .column({
        label: 'Payment method',
        key: 'paymentMethod',
        isSortable: true,
        getValue: item => item.context.customerConfig.paymentMethod,
        renderValue: val => (
            <div className={coalesceClassNames('CustomersTable__PaymentMethod', val)}>
                {CustomerPaymentMethodDisplay.display(val)}
            </div>
        ),
    })
    .column({
        label: 'Hold status',
        key: 'status',
        isSortable: true,
        getValue: item => item.context.customerConfig.status,
        renderValue: val => (
            <div className={coalesceClassNames('CustomersTable__Status', val)}>
                {CustomerHoldStatusDisplay.display(val)}
            </div>
        ),
    })
    .column({
        label: 'Dealer portal access',
        key: 'accessType',
        isSortable: true,
        align: 'center',
        getValue: item => item.accessType,
        renderValue: val => (val === CustomerAccessType.DealerPortal ? <Icons.Check /> : null),
    })
    .build();

export default function CustomersTable() {
    const query = customersApi.useCustomerListQuery();
    const [filteredData, setFilteredData] = useState<Customer[]>();
    const [searchParams] = useSearchParams();

    // highlight the currently open item id if any
    const { customerId: activeItemId } = useParams();

    const filters: DataTableFilterConfig[] = useMemo(
        () => [
            {
                label: 'Search',
                type: 'search',
                defaultValue: '',
                fields: ['context.mainCompany.name', 'context.mainCompany.email'],
                urlParam: 'search',
            },
            {
                label: 'Payment method',
                type: 'select',
                defaultValue: '',
                field: 'context.customerConfig.paymentMethod',
                urlParam: 'paymentMethod',
                options: CustomerPaymentMethodDisplay.options,
            },
            {
                label: 'Status',
                type: 'select',
                defaultValue: '',
                field: 'context.customerConfig.status',
                urlParam: 'status',
                options: CustomerHoldStatusDisplay.options,
            },
            // {
            //     label: 'Brand',
            //     type: 'autocomplete',
            //     defaultValue: '',
            //     field: 'brand',
            //     urlParam: 'brand',
            //     options: brandOptions,
            //     applyFilter: (val: string, item: Customer) =>
            //         item.brands.some(b => `${b.id}` === val),
            // },
        ],
        [],
    );

    return (
        <div className="CustomersTable">
            <div className="CustomersTable__FilterBar">
                <DataTableFilters
                    data={query.data}
                    filters={filters}
                    onChange={setFilteredData}
                />
            </div>

            <DataTable
                className="CustomersTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={filteredData}
                onRefresh={query.refetch}
                rowLinkTo={item => `${item.id}?${searchParams}`}
                rowIsHighlighted={item => `${item.id}` === activeItemId}
                zebra={true}
                useStickyHeader={true}
                useFrontEndSorting={true}
                isRefreshing={query.isFetching}
                columns={COLUMN_DEFS}
            />
        </div>
    );
}
